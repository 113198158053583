:root {
    --body-font: "Archivo", Arial, sans-serif;
    --heading-font: 'Archivo', serif;
    --sidebar-width: 11rem;
    --primary-color: rgb(58, 83, 60);
    --secondary-color: #fde599;
    --tertiary-color: #c4ebe9;
}

html {
    font-size: 17px;
    -webkit-text-size-adjust: 100%;
}

body {
    font-family: var(--body-font);
    background: #fff;
    margin-top: 2rem;
    margin-bottom: 0;
}

a {
    color: #fff;
    border-bottom: 0.0625rem dotted #fff;
    text-decoration: none;
}

a:hover {
    border-bottom-style: solid;
}

h1 {
    font-size: 3rem;
    letter-spacing: -0.06rem;
    margin: 3rem 0 0 0;
    color: var(--secondary-color);
}

h1, h2, h3, h4 {
    font-family: var(--heading-font);
    color: var(--secondary-color);
    font-weight: normal;
}

p {
    line-height: 150%;
    margin: 1.5em 0;
}

p.intro {
    font-size: 1.25rem;
    line-height: 150%;
    border-left: 0.27rem solid var(--secondary-color);
    padding-left: 1.5rem;
    margin: 2rem 0;
}

.cv {
    max-width: 900px;
    margin: 0 auto;
    background: var(--primary-color);
    color: #fff;
    border-radius: 2rem 2rem 0 0;
    padding: 0.5rem 4rem;
    position: relative;
}

.not-found {
    background: var(--primary-color);
    color: #fff;
}

.not-found main {
    margin: 2rem auto;
    max-width: 900px;
}

.contacts {
    list-style: none;
    background: var(--secondary-color);
    color: var(--primary-color);
    border-radius: 2rem;
    font-size: 0.9rem;
    margin: 2.5rem 0;
    padding: 0.625rem 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    text-align: center;
}

.contacts li {
    border-right: 0.0625rem solid var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.contacts li a {
    color: var(--primary-color);
    text-decoration: none;
    border-bottom: 0.0625rem dotted var(--primary-color);
}

.contacts li svg {
    fill: var(--primary-color);
    margin-right: 0.5rem;
    height: 1.25rem;
    width: 1.25rem;
}

.contacts li a:hover {
    border-bottom-style: solid;
}

.contacts li:last-child {
    border-right: 0;
}

.pills {
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    padding: 0;
    font-size: 1rem;
}

.pills li {
    margin: 0.625rem 0.625rem 0 0;
    border: 0.0625rem solid var(--tertiary-color);
    padding: 0.625rem 1rem;
    border-radius: 2rem;
    color: var(--tertiary-color);
}

.row {
    display: flex;
    flex-flow: row wrap;
}

.row h2 {
    width: var(--sidebar-width);
    margin: 1rem 0 1rem 0;
}

.row ul {
    width: calc(100% - var(--sidebar-width));
}

.row .roles {
    width: calc(100% - var(--sidebar-width));
}

.row.experience {
    margin-top: 2.5rem;
}

main section {
    width: 100%;
    display: flex;
    margin: 1.5em 0 1em 0;
    flex-flow: column wrap;
}

main section ul {
    flex: 1;
}

main section .role {
    padding: 1.5rem 1.5rem 0 1.5rem;
    border: 0.0625rem solid var(--tertiary-color);
    margin-bottom: 1rem;
    margin-top: 1rem;
    border-radius: 1rem;
}

main section .role:first-child {
    margin-top: 0;
}

main section .role:last-child {
    margin-bottom: 0;
}

main section .role p {
    font-size: 1rem;
}

main section header {
    display: grid;
    grid-template-rows: 1fr;
}

main section header h3 {
    margin: 0;
}

main section header p {
    margin: 0;
    padding-top: 0.33rem;
    font-family: var(--heading-font);
    color: var(--tertiary-color);
}

main section i {
    margin: 0;
    font-family: var(--heading-font);
    float: left;
    margin-right: 0.75rem;
    border-radius: 0.75rem;
    border: 0.0625rem solid var(--tertiary-color);
}

i {
    width: 3rem;
    height: 3rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

i.fr24 {
    background-image: url(icons/flightradar24.jpeg);
}

i.atomic {
    background-image: url(icons/atomic.jpeg);
}

i.bnz {
    background-image: url(icons/bnz.jpeg);
}

i.alphero {
    background-image: url(icons/alphero.jpeg);
}

i.waikato-uni {
    background-image: url(icons/waikato.jpeg);
}

i.tax-calc {
    background-image: url(icons/tax-calc.png);
}

.download a {
    position: absolute;
    top: 0;
    right: 3rem;
    margin: 0;
    border: 1px solid var(--secondary-color);
    border-top: 0;
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 0 0 1rem 1rem;
    color: var(--secondary-color);
    display: flex;
}

.download a:hover {
    background: var(--secondary-color);
    color: var(--primary-color);
}

.download a svg {
    fill: var(--secondary-color);
    margin-right: 0.25rem;
}

.download a:hover svg {
    fill: var(--primary-color);
}

@media screen and (max-width: 900px) {
    html {
        font-size: 15px;
    }

    body {
        background: var(--primary-color);
        padding: 0;
        margin: 0;
    }

    .cv, .not-found {
        padding: 0 1rem;
    }

    h1 {
        font-size: 2.5rem;
    }

    p.intro {
        font-size: 1.125rem;
    }

    .contacts {
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 1rem;
    }

    .contacts li {
        margin: 0.5rem 0;
    }

    .contacts li:nth-child(even) {
        border-right: 0;
    }

    .row ul, .row h2, .row .roles {
        width: 100%;
    }

    .download a {
        right: 1rem;
        padding-top: 1rem;
    }

    .download a {
        padding: 0.5rem;
        border-radius: 0 0 0.75rem 0.75rem;
    }

    .download a svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0;
    }

    .download span {
        display: none;
    }

    .cv {
        border-radius: 0;
        margin: 0;
        overflow: hidden;
        padding: 0 1.5rem;
    }
}

@media print {
    * {
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important; 
        print-color-adjust: exact !important;
    }

    html {
        font-size: 15px;
    }

    @page {
        margin: 0;
        counter-increment: page;

        @bottom-right {
            content: counter(page) "/3";
            color: var(--secondary-color);
            margin-top: -50px;
            margin-right: 20px;
            font-family: var(--body-font);
            z-index: 100;
        }
    }

    body, .cv {
        margin: 0;
        padding: 0;
    }

    .cv {
        background: transparent;
    }

    body {
        padding: 0 3rem;
        background: var(--primary-color);
    }

    h1 {
        margin-top: 3rem;
    }

    p.intro {
        font-size: 1rem;
    }

    .break {
        page-break-before: always;
        margin-top: 3rem !important;
    }

    .row.experience.break {
        padding-top: 3rem;
    }

    .download {
        display: none;
    }
}
